.currentInvoiceContainer {
  position: fixed;
  top: 81px;
  right: 0;
  background-color: #fff;
  border: 1px solid #999;

  .invoiceItem__name {
    display: inline-block;
    width: 225px;
  }

  .invoiceTotal {
    border-top: 1px solid #000;
    padding-left: 40px;
  }
}
