body.tagPage {
  background-color: #f1f7fa;
  font-family: "Open Sans", sans-serif;
  padding-top: 0;
}

.navbar-link {
  color: #00c483;
  float: right;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.navbar-link:hover {
  color: #00c483;
}

.container--farmInfo {
  background-color: #fff;
  border: 1px solid #e7e7e8;
  border-radius: 3px;
  min-height: 300px;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 600px) {
    border: none;
    background-color: transparent;
    margin: 0 15px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.container--farm,
.container--harvestInfo {
  background-color: #fff;
  border: 1px solid #e7e7e8;
  border-radius: 3px;
  margin-top: 20px;
  padding: 20px 10px 0;
  box-shadow: 7px 7px 17px -5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 7px 7px 17px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 7px 7px 17px -5px rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 600px) {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
}

.flex {
  display: flex;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

h1 {
  font-size: 32px;
  font-weight: 700;
  margin-top: 35px;

  @media screen and (max-width: 600px) {
    margin-top: 0;
  }
}

h2 {
  font-size: 22px;
  font-weight: 700;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #e3e3e4;
}

h4 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.container--tag {
  flex: 3;

  a {
    color: #00c483;
  }
}

.container--harvestInfo {
  flex: 2;
  border-left: 1px solid #e3e3e4;
  padding-top: 30px;

  @media screen and (max-width: 600px) {
    padding-top: 20px;
    border-left: none;
  }

  h3 {
    margin-top: 15px;
    margin-right: -40px;
    padding-left: 15px;
    padding-bottom: 15px;
    margin-bottom: 50px;

    @media screen and (max-width: 600px) {
      border-top: 1px solid #e3e3e4;
      margin-top: 30px;
      padding-top: 15px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.harvestInfoItem {
  position: relative;
  border-left: 4px solid rgb(184, 234, 228);
  padding-left: 35px;
  margin-left: 50px;
  min-height: 80px;

  img {
    position: absolute;
    top: 0;
    left: -14px;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 26px;
  }

  a {
    color: #06293e;
  }
}

.harvestInfoItem__last {
  border-color: #fff;
}
