.farmDetails--form {
  .removeBtn {
    border: none;
    background: transparent;
    position: absolute;
    right: 28px;
  }

  .farm-photo-input {
    label {
      display: block;
    }

    img {
      max-width: 500px;
      max-height: 250px;
    }
    .btn {
      margin-left: 20px;
    }
  }
}
