.billingTable {
	width: 100%;
	margin-bottom: 20px;

	th,
	td {
		padding: 2px;
		vertical-align: top;
	}

	tr:nth-child(2) {
		background: rgb(240, 240, 240);
	}
}
