//font awesome
@import '../../node_modules/font-awesome/scss/font-awesome.scss';

// Core variables and mixins
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins.scss';

// Reset and dependencies
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print.scss';

// Core CSS
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons.scss';

// Components
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close.scss';

// Components w/ JavaScript
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel.scss';

// Utility classes
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities.scss';

body {
	padding-top: 50px;
	background-color: #fff;
}

.list-group-farm-summary {
	padding: 0 15px;
}

.navbar-toggle {
	border: 1px solid #fff;

	.icon-bar {
		background-color: #888;
	}
}

.navbar {
	padding: 10px 0;
}

.navbar-nav {
	margin: 10px 0 0;
}
.navbar-default .navbar-nav > li > a,
.navbar .navbar-nav > li > a {
	color: white;
}

.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus,
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	background-color: transparent !important;
	color: #00c483;
}

h1,
h2,
h3,
h4,
label,
button {
	font-family: 'museo-sans', sans-serif;
}

.form-control {
	border-radius: 0;
	box-shadow: none;
}

.btn-sm,
.btn-default {
	border-radius: 0;
}

.btn-primary {
	background-color: #00c483;
	border: none;
	border-radius: 0;
}

.alert {
	position: fixed;
	top: 71px;
	right: 0;
	z-index: 999;
}

.alert-static {
	position: static;
}

.capitalize {
	text-transform: capitalize;
}

html,
body,
#app-mount {
	height: 100%;
}

body.reports {
	padding-top: 0;
}

body {
	font-family: 'Avenir Next', Arial, Helvetica, sans-serif;
}

.table-striped {
	tr {
		cursor: pointer;
	}
}

@media screen and (max-width: 768px) {
	.nav-tabs > li {
		float: none;
	}

	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:hover,
	.nav-tabs > li.active > a:focus {
		border: none;
	}
}
